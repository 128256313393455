@import 'common/font.scss';
@import 'common/variable.scss';
.title{
  font-family: 'BebasNeue';
  font-size: 32px;
  margin-bottom: 20px;
}
.label {
  color: $grey-color;
  font-size: 16px;
  font-family: $Roboto-Condensed;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.timeline {
  margin-top: 50px !important;
}
.listOfFilter {
  display: inline-block;
  vertical-align: middle;
  > li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
  }
}
