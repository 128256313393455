@import 'common/font.scss';
@import 'common/variable.scss';

.title {
  font-family: 'BebasNeue';
  font-size: 32px;
  margin-bottom: 20px;
}

.btnGreen {
  border: 2px solid $success;
  color: $success;
  min-width: 40px;
  width: 70%;
  font-size: 30px;
  margin: 0px auto 10px auto;
  display: block;

  &:hover {
    border: 2px solid $white;
    color: $white;
    background-color: $success;
  }

  &[class*="button_disabled"] {
    border-color: $extra-light-grey-color;
    color: $extra-light-grey-color;

    &:hover {
      border-color: $extra-light-grey-color;
      background-color: $extra-light-grey-color;
      color: $white;
    }
  }
}

.btnRed {
  border: 2px solid $error;
  color: $error;
  min-width: 40px;
  width: 70%;
  font-size: 30px;
  margin: 0px auto 10px auto;
  display: block;

  &:hover {
    border: 2px solid $white;
    color: $white;
    background-color: $error;
  }

  &[class*="button_disabled"] {
    border-color: $extra-light-grey-color;
    color: $extra-light-grey-color;
    background-color: $white;

    &:hover {
      border-color: $extra-light-grey-color;
      background-color: $extra-light-grey-color;
      color: $white;
    }
  }
}

.details {
  margin: 30px 0;

  > li {
    display: inline-block;
    vertical-align: middle;
    width: 70%;

    &:last-child {
      text-align: right;
      width: 30%;
    }
  }

  p {
    font-weight: 300;
    margin-bottom: 10px;
  }

  span {
    font-size: $font-title;
  }
}

.tableStyle {
  margin-top: 20px;
  overflow-y: scroll;
  height: 200px;

  tr {
    vertical-align: middle;
  }

  tr:hover .btnSelect {
    display: block;
  }
}

.rejectedRow:hover .btnSelect + span {
  display: none;
}

.spin {
  -webkit-animation: spin 1s infinite ease-in-out;
  animation: spin 1s infinite ease-in-out;
  display: block;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.subTitle {
  margin-top: 25px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.label {
  font-family: $Roboto;
  font-weight: 200;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;

  > strong {
    font-weight: 500;
  }
}

.modalTitle {
  margin-bottom: 10px;
}

.modalTitleRed {
  margin-bottom: 10px;
  color: red;
}

.modalContent {
  line-height: 20px;
}

.btnWrapper {
  padding-top: 10px;
  text-align: right;

  > button {
    width: 100px;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.messageWrapper {
  position: relative;

  > span {
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -11px;
    font-size: 12px;
  }
}

.messageRetrainWrapper {
  position: relative;

  > span {
    text-align: right;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -20px;
    font-size: 12px;
  }
}

.cancel {
  color: $light-grey-color;
}

.btnSelect {
  display: none;
}

.btnDelete {
  > svg {
    fill: $error;
  }
  display: none;
}

.active {
  display: flex;
  color: $success;
  align-items: center;
  font-size: 14px;

  > svg {
    margin-right: 10px;
  }
}

.modalTitle {
  margin-bottom: 20px;
  font-family: 'RobotoCondensed';
  font-size: 24px;
}

.modalContent {
  line-height: 20px;
  margin-bottom: 10px;

  & > strong {
    font-weight: 500;
  }
}

.btnWrapper {
  margin-top: 20px;
  text-align: right;

  > button {
    min-width: 100px;

    &:last-child {
      margin-left: 20px;
    }
  }
}
