$yellow-light: #faf9f3;
$primary-color: #d31145;
$primary-blue: #337ab7;
$primary-black: #333333;

$dark-grey-color: #666;
$grey-color: #999;
$light-grey-color: #ccc;
$extra-light-grey-color: #dddddd;
$near-white: #efefef;

$white: #fff;

$text: $primary-black;
$main-background: $yellow-light;
$card-background: $white;
$line-color: $extra-light-grey-color;
$link-color: $primary-blue;

$error: #ff5630;
$warning: #ffab00;
$success: #36b37e;
$info: #00b8d9;

$font-small: 14px;
$font-normal: 16px;
$font-medium: 18px;
$font-title: 20px;
