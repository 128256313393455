@import 'common/font.scss';
@import 'common/variable.scss';

.block {
  width: 540px;
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px dashed $line-color;
  margin-bottom: 25px;
  text-align: left;
  padding-bottom: 10px;

  >li {
    display: inline-block;
    vertical-align: top;
    width: 48%;
  }
}
