@import 'common/font.scss';
@import 'common/variable.scss';

.title {
  font-family: 'BebasNeue';
  font-size: 32px;
  margin-bottom: 20px;
}
.loadingWrapper {
  text-align: center;
  width: 100%;
}
.subTitle {
  margin-top: 25px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.label {
  font-family: $Roboto;
  font-weight: 200;
  font-size: 18px;
  margin-bottom: 20px;
  display: block;

  >strong {
      font-weight: 500;
    }
}

.lineLabel {
  font-family: $Roboto;
  font-weight: 200;
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: none;
}

.popupBtnWrapper {
  margin-top: 20px;
  text-align: right;

  > button:first-child {
    margin-right: 20px;
  }
}

.block {
  text-align: left;
}

.listCheckBox {
  flex-direction: row;
  > div {
    margin-right: 20px;
  }
}

.maxWidth {
  width: 100%;
  margin: 0;
  border: none;
}

.maxWidthBorder {
  margin: 0 0 20px 0;
  border-top: 1px dashed $dark-grey-color;
  width: 100%;
}

.online {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 12px;
  color: $text;

  span {
    background-color: $grey-color;
    color: $white;
    padding: 2px 5px;
    border-radius: 5px;
  }
}

.twoColumns {
  width: 100%;
  margin: 0;
  border: none;
  display: flex;
  justify-content: space-between;

  > li {
    width: 48%;
  }
}

.threeColumns {
  width: 100%;
  margin: 0;
  border: none;
  display: flex;
  justify-content: space-between;

  > li {
    width: 30%;
  }
}

.delete {
  color: $primary-color;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.5;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
}

.extraTitle {
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 10px;
}

.addMore {
  display: flex;
  justify-content: flex-end;
  border-top: 1px dashed $line-color;
}

.item {
  padding: 20px 20px 0 20px;
  border-radius: 5px;
  position: relative;

  &:nth-child(odd) {
    background: #eaeaea;
  }
}

.spin {
  -webkit-animation: spin 1s infinite ease-in-out;
  animation: spin 1s infinite ease-in-out;
  display: block;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.duplicated {
  border: 1px dashed $primary-color;
}

.modalTitle {
  margin-bottom: 20px;
  font-family: 'RobotoCondensed';
  font-size: 24px;
}

.modalContent {
  line-height: 20px;
  margin-bottom: 10px;
  &>strong {
    font-weight: 500;
  }
}

.btnWrapper {
  margin-top: 20px;
  text-align: right;

  >button {
    min-width: 100px;

    &:last-child {
      margin-left: 20px;
    }
  }
}