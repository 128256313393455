@import 'common/font.scss';
@import 'common/variable.scss';

.field {
  margin-bottom: 25px;
  position: relative;
}

.label {
  font-family: $Roboto-Condensed;
  font-weight: 200;
  font-size: 16px;
  margin-bottom: 10px;
  display: block;
}

input, .input {
  border-radius: 3px;
  border: 1px solid $line-color;
  padding: 9px;
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
  outline: none;

  >div:first-of-type {
    border: none !important;
    box-shadow: none !important;
  }
}

.selectWrapper {
  padding: 0;
}

.errorWrapper {
  border: 1px solid $error;
}

.error {
  position: absolute;
  left: 0px;
  bottom: -20px;
  color: $error;
  font-size: 14px;
}

.searchWrapper {
  position: relative;
}

.searchBtn {
  position: absolute;
  top: 5px;
  right: 10px;
}

.hasIcon {
  padding-left: 40px;
}

.iconWrapper {
  position: absolute;
  bottom: 5px;
  left: 0;

  >svg {
    display: block;
    color: $grey-color;
  }
}

.iconSliderWrapper {
  position: absolute;
  bottom: 5px;
  left: 0;
  margin-left: 25%;

  >svg {
    display: block;
    color: $grey-color;
  }
}

.columnWrapper {
  li {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 50%;

    >input {
      width: 80%;
    }

    &:first-child {
      width: 50%;
    }
  }
}

.selectAndInput {
  >ul li {
    display: inline-block;
    vertical-align: middle;
    width: 35%;

    &:last-child {
      width: 57%;
      float: right;
    }
  }
}

.mandatory{
  color: $error;
}
