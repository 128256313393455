.header {
  padding: 0 20px;
  box-shadow: 0px 1px 3px 0px var(--color-primary-line);
  z-index: 2;
  &.doctor {
    background: #f8116a;
    justify-content: flex-start;
    flex-wrap: wrap;
    h2.title {
      color: white;
      text-transform: capitalize;
      > span {
        width: 40px;
        background-size: contain;
        margin-top: 10px;
        margin-right: 10px;
        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .menus {
      color: white;
      margin-left: 10px;
      flex-grow: 1;
      > span {
        margin: 0 10px;
        display: inline-block;
        &.active {
          border-bottom: 1px solid white;
        }
      }
    }

    .user {
      color: white;
      font-size: 11px;
      line-height: 1.5em;
      text-align: right;
      font-family: var(--font-RobotoCondensed);
    }
  }
}

.title {
  color: var(--color-primary-main);
  display: flex;
  align-items: center;
  line-height: 0.9em;
  > img {
    margin-right: calc(var(--size-spacing)/2);
  }
  > span {
    display: inline-block;
    width: 200px;
    height: 62px;
    background-size: contain;
    margin-right: calc(var(--size-spacing)/2);
  }
}
