@import 'common/font.scss';
@import 'common/variable.scss';

.pickerWrapper {
  margin-top: 10px;
  >div {
    margin: 0;
    display: inline-block;
    width: 100%;
  }
}

.noManager {
  border: 1px solid $extra-light-grey-color;
  padding: 10px;
  display: block;
  color: $light-grey-color;
  border-radius: 3px;
}