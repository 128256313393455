@import 'common/font.scss';
@import 'common/variable.scss';

.table {
  width: 100%;
  border-collapse: collapse;
}

.head td {
  font-family: $Roboto-Condensed;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid $grey-color;
  padding: 10px;
}

.body {
  tr {
    &:nth-child(even) {
      background-color: $near-white;
    }

    &:hover {
      background-color: $extra-light-grey-color;
    }
  }

  td {
    font-family: $Roboto;
    font-weight: 40;
    font-size: 16px;
    border-bottom: 1px dashed $grey-color;
    padding: 10px;

    svg {
      // color: $grey-color;
    }
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
