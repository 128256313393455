@import 'common/font.scss';
@import 'common/variable.scss';

.title {
  font-family: 'BebasNeue';
  font-size: 32px;
  margin-bottom: 20px;
}

.btnWrapper {
  margin-top: 10px;
  text-align: right;
  margin-bottom: 10px;

  label,
  input {
    text-align: left;
  }
}

.deleteBtn {
  padding: 0;
  margin-left: 20px;
  background: none;

  span {
    color: $primary-color !important;
  }
  svg {
    fill: $primary-color  !important;
  }
}

.Active {
  color: $success;
  font-weight: 500;
}

.realDeleteBtn {
  padding: 0;
  margin-right: 30px;

  span {
    color: $primary-color  !important;
  }

  svg {
    fill: $primary-color  !important;
  }
}

.addUserWrapper {
  min-width: 300px;
  > div{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0px;
    }
  }
}

.editBtn {
  padding: 0;
  margin-right: 30px;

  span,
  svg {
    color: $primary-blue !important;
  }
}

.cancelBtn {
  padding: 0;

  span,
  svg {
    color: $text !important;
  }
}

.popupBtnWrapper {
  text-align: right;

  >button:first-child {
    margin-right: 20px;
  }
}

.zeroMargin {
  margin-bottom: 0;
}

.spin {
  -webkit-animation: spin 1s infinite ease-in-out;
  animation: spin 1s infinite ease-in-out;
  display: block;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
