@import 'common/font.scss';
@import 'common/variable.scss';

.title {
  font-family: 'BebasNeue';
  font-size: 32px;
  margin-bottom: 20px;
}

.file {
  border-radius: 3px;
  box-shadow: 0 0 3px 0 $light-grey-color;
  padding: 10px 35px 10px 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% / 2 - 55px);
  margin: 10px 0;
}

.foldedCorner {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 35px;
  padding: 38px 10px 10px 10px;
  color: $white;
  background: #d37911;
  background: linear-gradient(to left bottom, transparent 50%, rgba(0, 0, 0, 0.4) 0) no-repeat 100% 0 / 15px 15px,
    linear-gradient(-135deg, transparent 10px, #d37911 0);
}

.info {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  width: calc(100% - 70px);

  h4 {
    font-size: 16px;
    font-family: 'RobotoCondensed';
    line-height: 1em;
    margin-bottom: 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p {
    color: $grey-color;
    font-weight: 300;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    border-top: 1px dashed #eaeaea;
    padding-top: 5px;
  }
}

.iconWrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.block {
  padding: 5px 0;

  h4 {
    margin-bottom: 10px;
    color: $grey-color;
  }
}

.button {
  margin-bottom: 5px;

  &:hover {
    color: $primary-color;
  }
}

.placeholder {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.dropZone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 78px;
  cursor: pointer;
}

.wrapper {
  > :nth-child(2n + 0) {
    margin-left: 20px;
  }
}

.loading {
  height: 40px;
}
.tags {
  margin-top: 5px;
}
.tag {
  background: $extra-light-grey-color;
  padding: 2px 10px;
  border-radius: 3px;
  font-size: 12px;
  display: inline-block;
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tag1 {
  padding: 0px 10px 0 0;
  border-radius: 3px;
  font-size: 12px;
  display: inline-block;
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  background: none !important;
  color: #999 !important;
}

.claimType{
  background: #dde3ec;
  color: $text;
  margin-left: 10px;
  &:first-child {
    margin-left: 0px;
  }
}
.valid {
  background: $success;
  margin-left: 10px;
  color: $white;
}

.invalid {
  background: $error;
  margin-left: 10px;
  color: $white;
}

.waitForApproval {
  background: $warning;
  margin-left: 10px;
  color: $white;
}

.hiddenInput {
  display: none;
}

.modalTitle {
  margin-bottom: 20px;
  font-family: 'RobotoCondensed';
  font-size: 24px;
}

.modalContent {
  line-height: 20px;
  & > strong {
    font-weight: 500;
  }
}

.btnWrapper {
  margin-top: 20px;
  text-align: right;

  > button {
    min-width: 100px;

    &:last-child {
      margin-left: 20px;
    }
  }
}

.modalContentFileType {
  margin: 10px 0 0 0;
  line-height: 1.5em;
}

.modalSelect {
  margin: 10px 0;
}

.listCheckBox {
  flex-direction: row;
  > div {
    margin-right: 20px;
  }
}

.label {
  font-family: $Roboto;
  font-weight: 200;
  font-size: 20px;
  margin-bottom: 10px;
  display: block;
}

.form {
  width: 270px;
  p{
    font-size: 14px;
  }
}

.realDeleteBtn {
  padding: 0;

  span,
  svg {
    color: $primary-color !important;
  }
}

.cancelBtn {
  padding: 0;

  span,
  svg {
    color: $text !important;
  }
}

.fileReview {
  min-width: 300px;
  width: 90%;
  height: 95%;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    color: $primary-color;
  }
}

.tableWrapper {
  overflow: auto;
  width: 100%;
  height: calc(100% - 14px);
}

.tableClassName {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;

  th {
    font-family: $Roboto-Condensed;
    font-weight: 600;
    border-bottom: 1px solid $grey-color;
    padding: 5px;
  }
}

.tableRowClassName {
  &:nth-child(even) {
    background-color: $near-white;
  }

  &:last-child {
    td {
      border-bottom: none;
    }
  }

  &:hover {
    background-color: $extra-light-grey-color;
  }
}

.tagWrapper {
  white-space: nowrap;
  width: 93%;
}

.subTitle {
  margin-top: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.maxInput {
  width: 60%;
}

.popupBtnWrapper {
  text-align: right;

  > button:first-child {
    margin-right: 20px;
  }
}

.maxWidth {
  width: 100%;
  margin: 0;
  border: none;
}

.doubleField {
  > div {
    width: 70%;
    display: inline-block;
    vertical-align: bottom;
  }

  > span {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 25px;
    margin-left: 10px;
  }
}

.spin {
  -webkit-animation: spin 1s infinite ease-in-out;
  animation: spin 1s infinite ease-in-out;
  display: block;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.tip {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.loadingWrapper{
  text-align: center;
  width: 100%;
}