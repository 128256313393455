@import 'common/font.scss';
@import 'common/variable.scss';

.wrapper {
  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -400px;
  }
}

.messageBox {
  height: 200px;
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: 50px;
  color: #333;
  font-family: Roboto;
  font-weight: 300;
  h1 {
    font-size: 60px;
    line-height: 46px;
    margin-bottom: 40px;
    font-family: $Roboto-Condensed;
  }
  p{
    line-height: 24px;
  }
}

#Polygon1,
#Polygon2,
#Polygon3,
#Polygon4,
#Polygon4,
#Polygon5 {
  animation: float 1s infinite ease-in-out alternate;
}

#Polygon2 {
  animation-delay: 0.2s;
}

#Polygon3 {
  animation-delay: 0.4s;
}

#Polygon4 {
  animation-delay: 0.6s;
}

#Polygon5 {
  animation-delay: 0.8s;
}

@keyframes float {
  100% {
    transform: translateY(20px);
  }
}

@media (max-width: 450px) {
  wrapper {
    & > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -250px;
      margin-left: -190px;
    }
  }

  .messageBox {
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -190px;
    text-align: center;
  }
}
