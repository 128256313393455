@import 'common/font.scss';
@import 'common/variable.scss';

.btnWrappers {
  display: flex;
  justify-content: flex-end;

  >* {
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
    }
  }

  label,
  input {
    text-align: left;
  }
}


.downloadCsv {
  position: absolute;
  top: 20px;
  right: 60px;
  z-index: 1;
}

.zoomBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.label {
  font-family: $Roboto-Condensed;
  font-weight: 200;
  font-size: 16px;
  margin-bottom: 10px;
  display: block;
}

.popupBtnWrapper {
  text-align: right;

  >button:first-child {
    margin-right: 20px;
  }
}

.itemPerPage {
  display: inline-block;
  vertical-align: middle;

  >label {
    font-family: $Roboto-Condensed;
    font-weight: 300;
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;
  }

  >div {
    width: 80px;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
}

.modal {
  width: 90%;
}

.btnWrapper {
  padding-top: 10px;
  text-align: right;

  >button {
    width: 100px;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.arrowWrapper {
  position: relative;
  z-index: 1;
}

.prevChart {
  position: absolute;
  top: 0;
  left: 0px;
}

.nextChart {
  position: absolute;
  top: 0;
  right: 0px;
}

.checkboxWrapper {
  padding-bottom: 20px;
}

.checkbox {
  position: absolute;
  top: 17px;
  right: 65px;
  z-index: 1;
  padding: 0;
}
.slideBtn{
position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
}
